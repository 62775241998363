import { axios, AxiosResponse } from "@sdk/axios";
import { iOrganization } from "@sdk/user-management/user-management.models";
import {
  iAsanaSearchResults,
  iAsanaTask,
  iAsanaWorkspace,
} from "./asana/asana-api-models";
import { iCalEventTypesRes } from "./cal/cal-api-models";
import { iCalendlyEventTypeRes } from "./calendly/calendly-api-models";
import {
  iClickUpFolder,
  iClickUpList,
  iClickUpSpace,
  iClickUpTask,
  iClickUpTeam,
} from "./click-up/click-up-api-models";
import {
  iFreshDeskCustomer,
  iFreshDeskTicket,
} from "./fresh-desk/fresh-desk-api-models";
import { iGoogleMeetEvent } from "./g-meet/g-meet-api-models";
import { iGitHubIssue, iGitHubRepo } from "./github/github-api-models";
import {
  iHubSpotContact,
  iHubSpotDeal,
  iHubSpotObject,
  iHubspotTokenTestResult,
} from "./hubspot/hubspot-api-models";
import {
  iJiraAccessibleResource,
  iJiraCreateIssueMetaData,
  iJiraProject,
  iJiraTaskRoot,
} from "./jira/jira-api-models";
import {
  DatabaseObjectResponse,
  NotionSearchResponse,
  PageObjectResponse,
} from "./notion/notion-models";
import {
  iPipeDriveActivity,
  iPipeDriveDeal,
  iPipeDrivePerson,
  iPipeDriveResponse,
  iPipeDriveSearchResults,
} from "./pipedrive/pipedrive-api-models";
import {
  iStripeCustomer,
  iStripeCustomerSubscription,
  iStripeInvoice,
} from "./stripe/stripe-api-models";
import { iZenDeskTicket, iZenDeskUser } from "./zen-desk/zendesk-api-models";
import {
  iZohoDepartment,
  iZohoDeskCustomer,
  iZohoDeskTicket,
} from "./zoho-desk/zoho-desk-api-models";

export type iExternalAppType =
  // Payment Providers
  | "STRIPE"
  // Task Management
  | "NOTION"
  | "ASANA"
  | "CLICK_UP"
  | "JIRA"
  | "GIT_HUB"
  | "LINEAR"
  // CRM
  | "HUB_SPOT"
  | "PIPE_DRIVE"
  // Help Desk
  | "ZENDESK"
  | "FRESH_DESK"
  | "ZOHO_DESK"
  // Meetings, and Video Conference
  | "AIR_CALL"
  | "GOOGLE_MEET"
  | "CALENDLY"
  | "CAL"
  // Team Communication
  | "SLACK";

export class ExternalAccountsServices {
  configure(config: { basePath?: string; token?: string }) {
    Object.assign(this.config, config);
  }
  constructor(protected config: { basePath: string; token: string }) {}

  externalAccounts = {
    completeIntegration: async (data: {
      type: iExternalAppType;
      data: {
        code?: string;
        accessToken?: string;
        apiKey?: string;
        state?: string;
      };
    }) => {
      const results: AxiosResponse<iOrganization> = await axios.post(
        this.config.basePath + "/external-apps/complete-integration",
        data,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    updateConfig: async (data: { type: iExternalAppType; config: any }) => {
      const results: AxiosResponse<iOrganization> = await axios.post(
        this.config.basePath + "/external-apps/update-config",
        data,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    removeIntegration: async (data: { type: iExternalAppType }) => {
      const results: AxiosResponse<iOrganization> = await axios.post(
        this.config.basePath + "/external-apps/remove-integration",
        data,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  stripe = {
    searchCustomers: async (req: {
      name?: string;
      email?: string;
      phone?: string;
    }) => {
      const results: AxiosResponse<iStripeCustomer[]> = await axios.post(
        this.config.basePath + "/stripe/search-customers",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getCustomerById: async (req: { customerId: string }) => {
      const results: AxiosResponse<iStripeCustomer> = await axios.post(
        this.config.basePath + "/stripe/get-customer-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getCustomerSubscriptions: async (req: { customerId: string }) => {
      const results: AxiosResponse<{ data: iStripeCustomerSubscription[] }> =
        await axios.post(
          this.config.basePath + "/stripe/get-customer-subscription",
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getCustomerInvoices: async (req: { customerId: string }) => {
      const results: AxiosResponse<{ data: iStripeInvoice[] }> =
        await axios.post(
          this.config.basePath + "/stripe/get-customer-invoices",
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
  };

  notion = {
    getAvailableDatabases: async (req: { searchTerm: string }) => {
      const results: AxiosResponse<
        NotionSearchResponse<DatabaseObjectResponse>
      > = await axios.post(
        this.config.basePath + "/notion/get-available-databases",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getPageById: async (req: { pageId: string }) => {
      const results: AxiosResponse<PageObjectResponse> = await axios.post(
        this.config.basePath + "/notion/get-page-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchPages: async (req: { searchTerm: string }) => {
      const results: AxiosResponse<NotionSearchResponse<PageObjectResponse>> =
        await axios.post(this.config.basePath + "/notion/search-pages", req, {
          headers: {
            Authorization: this.config.token,
          },
        });
      return results.data;
    },
    searchDBRecords: async (req: {
      searchTerm: string;
      dataBaseId: string;
    }) => {
      const results: AxiosResponse<NotionSearchResponse<PageObjectResponse>> =
        await axios.post(
          this.config.basePath + "/notion/search-db-records",
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    addDBRecord: async (req: { properties: any; dataBaseId: string }) => {
      const results: AxiosResponse<PageObjectResponse> = await axios.post(
        this.config.basePath + "/notion/add-db-record",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  asana = {
    getAvailableWorkspaces: async (req: {}) => {
      const results: AxiosResponse<{ data: iAsanaWorkspace[] }> =
        await axios.post(
          this.config.basePath + "/asana/get-available-workspaces",
          req,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getAvailableProjects: async (req: { workspaceId: string }) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/asana/get-available-projects",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getTaskById: async (req: { taskId: string }) => {
      const results: AxiosResponse<iAsanaTask> = await axios.post(
        this.config.basePath + "/asana/get-task-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAllTasksOfProject: async (req: { projectId: string }) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/asana/get-tasks-by-project",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchTasks: async (req: {
      workspaceId: string;
      projectId: string;
      searchTerm: string;
    }) => {
      const results: AxiosResponse<iAsanaSearchResults> = await axios.post(
        this.config.basePath + "/asana/search-tasks",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    addTask: async (req: {
      workspaceId: string;
      projectId: string;
      name: string;
    }) => {
      const results: AxiosResponse<iAsanaTask> = await axios.post(
        this.config.basePath + "/asana/add-task",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  clickUp = {
    getAvailableWorkspaces: async () => {
      const results: AxiosResponse<{
        teams: iClickUpTeam[];
      }> = await axios.post(
        this.config.basePath + "/click-up/get-available-workspaces",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAvailableSpaces: async (req: { teamId: string }) => {
      const results: AxiosResponse<{
        spaces: iClickUpSpace[];
      }> = await axios.post(
        this.config.basePath + "/click-up/get-available-spaces",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAvailableFolders: async (req: { spaceId: string }) => {
      const results: AxiosResponse<{
        folders: iClickUpFolder[];
      }> = await axios.post(
        this.config.basePath + "/click-up/get-available-folders",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAvailableLists: async (req: { folderId: string }) => {
      const results: AxiosResponse<{
        lists: iClickUpList[];
      }> = await axios.post(
        this.config.basePath + "/click-up/get-available-lists",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAvailableTasks: async (req: { listId: string }) => {
      const results: AxiosResponse<{
        tasks: iClickUpTask[];
      }> = await axios.post(
        this.config.basePath + "/click-up/get-available-tasks",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getTaskById: async (req: { taskId: string }) => {
      const results: AxiosResponse<iClickUpTask> = await axios.post(
        this.config.basePath + "/click-up/get-task-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createTask: async (req: { listId: string; taskDakData }) => {
      const results: AxiosResponse<iClickUpTask> = await axios.post(
        this.config.basePath + "/click-up/create-task",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  jira = {
    getAvailableResources: async () => {
      const results: AxiosResponse<iJiraAccessibleResource[]> =
        await axios.post(
          this.config.basePath + "/jira/get-available-resources",
          {},
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getAvailableProjects: async (req: { cloudId?: string }) => {
      const results: AxiosResponse<iJiraProject[]> = await axios.post(
        this.config.basePath + "/jira/get-available-projects",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAvailableUsers: async () => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/jira/get-available-users",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getProjectById: async (req: { projectId: string }) => {
      const results: AxiosResponse<iJiraProject> = await axios.post(
        this.config.basePath + "/jira/get-project-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchIssues: async (req: { searchTerm: string }) => {
      const results: AxiosResponse<{ issues: iJiraTaskRoot[] }> =
        await axios.post(this.config.basePath + "/jira/search-issues", req, {
          headers: {
            Authorization: this.config.token,
          },
        });
      return results.data;
    },
    getTaskById: async (req: { issueId: string }) => {
      const results: AxiosResponse<iJiraTaskRoot> = await axios.post(
        this.config.basePath + "/jira/get-issue-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getCreateIssueMeta: async (req: {}) => {
      const results: AxiosResponse<iJiraCreateIssueMetaData> = await axios.post(
        this.config.basePath + "/jira/get-create-issue-meta",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createTask: async (req: { issueData: any }) => {
      const results: AxiosResponse<iJiraTaskRoot> = await axios.post(
        this.config.basePath + "/jira/create-task",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  gitHub = {
    getAllRepos: async (body: {}) => {
      const results: AxiosResponse<iGitHubRepo[]> = await axios.post(
        this.config.basePath + "/git-hub/get-all-repos",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getAllIssues: async (body: { repo: string; owner: string }) => {
      const results: AxiosResponse<iGitHubIssue[]> = await axios.post(
        this.config.basePath + "/git-hub/get-all-issues",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getIssueById: async (body: {
      repo: string;
      owner: string;
      issueNumber: number;
    }) => {
      const results: AxiosResponse<iGitHubIssue> = await axios.post(
        this.config.basePath + "/git-hub/get-issue-by-id",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createIssue: async (body: {
      repo: string;
      owner: string;
      issue: {
        title: string;
        body?: string;
      };
    }) => {
      const results: AxiosResponse<iGitHubIssue> = await axios.post(
        this.config.basePath + "/git-hub/create-issue",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editIssue: async (body: {
      repo: string;
      owner: string;
      issueNumber: number;
      updates: {
        body?: string;
      };
    }) => {
      const results: AxiosResponse<iGitHubIssue> = await axios.post(
        this.config.basePath + "/git-hub/edit-issue",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchAnIssue: async (body: {
      repo: string;
      owner: string;
      searchQuery: string;
    }) => {
      const results: AxiosResponse<{
        total_count: number;
        incomplete_results: boolean;
        items: iGitHubIssue[];
      }> = await axios.post(
        this.config.basePath + "/git-hub/search-issues",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  linear = {
    getAvailableTeams: async () => {
      const results: AxiosResponse<any[]> = await axios.post(
        this.config.basePath + "/linear/get-available-teams",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },

    getIssueById: async (req: { issueId: string }) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/linear/get-issue-by-id",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },

    searchIssues: async (req: { searchTerm: string }) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/linear/search-issues",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    addIssue: async (req: {
      name: string;
      description: string;
      teamId: string;
    }) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/linear/add-issue",
        req,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  hubSpot = {
    testToken: async () => {
      const results: AxiosResponse<iHubspotTokenTestResult> = await axios.post(
        this.config.basePath + "/hub-spot/test-token",
        {},
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchContacts: async (body: { query: string }) => {
      const results: AxiosResponse<iHubSpotObject<iHubSpotContact>[]> =
        await axios.post(
          this.config.basePath + "/hub-spot/search-contacts",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactById: async (body: { contactId: string }) => {
      const results: AxiosResponse<iHubSpotObject<iHubSpotContact>> =
        await axios.post(
          this.config.basePath + "/hub-spot/get-contact-by-id",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactDeals: async (body: { contactId: string }) => {
      const results: AxiosResponse<iHubSpotObject<iHubSpotDeal>[]> =
        await axios.post(
          this.config.basePath + "/hub-spot/get-contact-deals",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactTickets: async (body: { contactId: string }) => {
      const results: AxiosResponse<any[]> = await axios.post(
        this.config.basePath + "/hub-spot/get-contact-tickets",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createContact: async (body: Partial<iHubSpotContact>) => {
      const results: AxiosResponse<iHubSpotObject<iHubSpotContact>> =
        await axios.post(
          this.config.basePath + "/hub-spot/create-contact",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    editContact: async (body: {
      contactId: string;
      edits: Partial<iHubSpotContact>;
    }) => {
      const results: AxiosResponse<iHubSpotObject<iHubSpotContact>> =
        await axios.post(
          this.config.basePath + "/hub-spot/edit-contact",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
  };

  pipeDrive = {
    searchContacts: async (body: { query: string }) => {
      const results: AxiosResponse<iPipeDriveSearchResults<iPipeDrivePerson>> =
        await axios.post(
          this.config.basePath + "/pipe-drive/search-contacts",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactById: async (body: { contactId: string }) => {
      const results: AxiosResponse<iPipeDriveResponse<iPipeDrivePerson>> =
        await axios.post(
          this.config.basePath + "/pipe-drive/get-contact-by-id",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactDeals: async (body: { contactId: string }) => {
      const results: AxiosResponse<iPipeDriveResponse<iPipeDriveDeal>[]> =
        await axios.post(
          this.config.basePath + "/pipe-drive/get-contact-deals",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    getContactActivities: async (body: { contactId: string }) => {
      const results: AxiosResponse<iPipeDriveResponse<iPipeDriveActivity>[]> =
        await axios.post(
          this.config.basePath + "/pipe-drive/get-contact-activities",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    createContact: async (body: Partial<any>) => {
      const results: AxiosResponse<iPipeDriveResponse<iPipeDrivePerson>> =
        await axios.post(
          this.config.basePath + "/pipe-drive/create-contact",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    editContact: async (body: { contactId: string; edits: Partial<any> }) => {
      const results: AxiosResponse<iPipeDriveResponse<iPipeDrivePerson>> =
        await axios.post(
          this.config.basePath + "/pipe-drive/edit-contact",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
  };

  zenDesk = {
    searchContacts: async (body: { query: string }) => {
      const results: AxiosResponse<iZenDeskUser[]> = await axios.post(
        this.config.basePath + "/zen-desk/search-contacts",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactById: async (body: { contactId: number }) => {
      const results: AxiosResponse<iZenDeskUser> = await axios.post(
        this.config.basePath + "/zen-desk/get-contact-by-id",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactTickets: async (body: { contactId: number }) => {
      const results: AxiosResponse<iZenDeskTicket[]> = await axios.post(
        this.config.basePath + "/zen-desk/get-contact-tickets",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createContact: async (body: Partial<iZenDeskUser>) => {
      const results: AxiosResponse<iZenDeskUser> = await axios.post(
        this.config.basePath + "/zen-desk/create-contact",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editContact: async (body: {
      contactId: number;
      edits: Partial<iZenDeskUser>;
    }) => {
      const results: AxiosResponse<iZenDeskUser> = await axios.post(
        this.config.basePath + "/zen-desk/edit-contact",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createTicket: async (body: Partial<any>) => {
      const results: AxiosResponse<iZenDeskTicket> = await axios.post(
        this.config.basePath + "/zen-desk/create-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editTicket: async (body: {
      ticketId: number;
      edits: Partial<iZenDeskTicket>;
    }) => {
      const results: AxiosResponse<{ result: iZenDeskTicket }> =
        await axios.post(this.config.basePath + "/zen-desk/edit-ticket", body, {
          headers: {
            Authorization: this.config.token,
          },
        });
      return results.data;
    },
  };

  freshDesk = {
    getAllDepartments: async (body: {}) => {
      const results: AxiosResponse<any[]> = await axios.post(
        this.config.basePath + "/fresh-desk/get-all-departments",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    searchContacts: async (body: { query: string }) => {
      const results: AxiosResponse<{
        total: number;
        results: iFreshDeskCustomer[];
      }> = await axios.post(
        this.config.basePath + "/fresh-desk/search-contacts",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactById: async (body: { contactId: number }) => {
      const results: AxiosResponse<iFreshDeskCustomer> = await axios.post(
        this.config.basePath + "/fresh-desk/get-contact-by-id",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactTickets: async (body: { contactId: number }) => {
      const results: AxiosResponse<iFreshDeskTicket[]> = await axios.post(
        this.config.basePath + "/fresh-desk/get-contact-tickets",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createContact: async (body: Partial<iFreshDeskCustomer>) => {
      const results: AxiosResponse<iFreshDeskCustomer> = await axios.post(
        this.config.basePath + "/fresh-desk/create-contact",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editContact: async (body: {
      contactId: number;
      edits: Partial<iFreshDeskCustomer>;
    }) => {
      const results: AxiosResponse<iFreshDeskCustomer> = await axios.post(
        this.config.basePath + "/fresh-desk/edit-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createTicket: async (body: Partial<iFreshDeskTicket>) => {
      const results: AxiosResponse<iFreshDeskTicket> = await axios.post(
        this.config.basePath + "/fresh-desk/create-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editTicket: async (body: {
      ticketId: number;
      edits: Partial<iFreshDeskTicket>;
    }) => {
      const results: AxiosResponse<iFreshDeskTicket> = await axios.post(
        this.config.basePath + "/fresh-desk/edit-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  zohoDesk = {
    getAllDepartments: async (body: {}) => {
      const results: AxiosResponse<{ data: iZohoDepartment[] }> =
        await axios.post(
          this.config.basePath + "/zoho-desk/get-all-departments",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    searchContacts: async (body: { query: string }) => {
      const results: AxiosResponse<{
        count: number;
        data: iZohoDeskCustomer[];
      }> = await axios.post(
        this.config.basePath + "/zoho-desk/search-contacts",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactById: async (body: { contactId: string }) => {
      const results: AxiosResponse<iZohoDeskCustomer> = await axios.post(
        this.config.basePath + "/zoho-desk/get-contact-by-id",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    getContactTickets: async (body: { contactId: string }) => {
      const results: AxiosResponse<{ data: iZohoDeskTicket[] }> =
        await axios.post(
          this.config.basePath + "/zoho-desk/get-contact-tickets",
          body,
          {
            headers: {
              Authorization: this.config.token,
            },
          },
        );
      return results.data;
    },
    createContact: async (body: Partial<iZohoDeskCustomer>) => {
      const results: AxiosResponse<iZohoDeskCustomer> = await axios.post(
        this.config.basePath + "/zoho-desk/create-contact",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editContact: async (body: {
      contactId: string;
      edits: Partial<iZohoDeskCustomer>;
    }) => {
      const results: AxiosResponse<iZohoDeskCustomer> = await axios.post(
        this.config.basePath + "/zoho-desk/edit-contact",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    createTicket: async (body: Partial<iZohoDeskTicket>) => {
      const results: AxiosResponse<iZohoDeskTicket> = await axios.post(
        this.config.basePath + "/zoho-desk/create-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    editTicket: async (body: {
      ticketId: string;
      edits: Partial<iZohoDeskTicket>;
    }) => {
      const results: AxiosResponse<iZohoDeskTicket> = await axios.post(
        this.config.basePath + "/zoho-desk/edit-ticket",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  gMeet = {
    getPrimaryCalendar: async (body: any) => {
      const results: AxiosResponse<any> = await axios.post(
        this.config.basePath + "/google-meet/get-primary-calendar",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
    scheduleEvent: async (body: any) => {
      const results: AxiosResponse<iGoogleMeetEvent> = await axios.post(
        this.config.basePath + "/google-meet/schedule-event",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  calendly = {
    getAllEventTypes: async (body: any) => {
      const results: AxiosResponse<iCalendlyEventTypeRes> = await axios.post(
        this.config.basePath + "/calendly/get-all-event-types",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };

  cal = {
    getAllEventTypes: async (body: any) => {
      const results: AxiosResponse<iCalEventTypesRes> = await axios.post(
        this.config.basePath + "/cal/get-all-event-types",
        body,
        {
          headers: {
            Authorization: this.config.token,
          },
        },
      );
      return results.data;
    },
  };
}
