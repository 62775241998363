import { useSDK, useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iExternalAppConfig_Linear } from "@sdk/user-management/user-management.models";
import { Alert, Button, Form, Select, Spin, Tag, Tooltip, message } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import {
  LoadingIndicatorWithSpin,
  LoadingIndicatorWithoutSpin,
} from "components/common/loading-indicator/loading-indicator";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectIntegrationConfig_Linear } from "store/modules/workspace/workspace.selectors";

export const LinearIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_Linear);
  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (code: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "LINEAR",
            data: {
              code,
            },
          }),
        successMessage: "Integration has been successful",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: removeIntegration, isProcessing: isRemoving } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => () =>
          SDK.externalAccounts.removeIntegration({
            type: "LINEAR",
          }),
        successMessage: "Integration has been removed",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const { doAction: saveConfig, isProcessing: isSavingConfig } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (config) =>
          SDK.externalAccounts.updateConfig({
            type: "LINEAR",
            config,
          }),
        successMessage: "Configuration has been saved",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const [form] = Form.useForm();

  const [formValues, setFormValues] = useState(
    integrationConfig?.config || ({} as iExternalAppConfig_Linear),
  );

  useEffect(() => {
    if (integrationConfig?.config) {
      setFormValues(integrationConfig?.config);
    }
  }, [integrationConfig?.config]);
  const [isConfigEditMode, setConfigEditMode] = useState(false);

  const { data: teamOptions, isLoading: isTeamsLoading } = useSDK(
    (SDK) =>
      SDK.linear.getAvailableTeams().then((data) => {
        return (data || []).map((item) => ({
          value: item.id,
          label: item.name,
        }));
      }),
    [integrationConfig],
    !integrationConfig || !isConfigEditMode,
    [],
  );

  const onSaveConfig = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const formValues = form.getFieldsValue();

          const selectedTeam = teamOptions.find(
            (item) => item.value === formValues.teamId,
          );

          saveConfig({
            ...formValues,
            isReady: true,
            teamName: selectedTeam?.label,
          } as iExternalAppConfig_Linear).then((d) => {
            setConfigEditMode(false);
          });
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [form, saveConfig, teamOptions],
  );

  const {
    data: test,
    isLoading: isTestingIntegration,
    error,
    reload: reTestIntegration,
  } = useSDK(
    (SDK) => SDK.linear.getAvailableTeams(),
    [integrationConfig?.config?.isReady],
    !integrationConfig?.config?.isReady,
  );

  useEffect(() => {
    if (integrationConfig && !integrationConfig.config.isReady) {
      setConfigEditMode(true);
    }
  }, [integrationConfig]);

  return (
    <>
      {integrationConfig && (
        <div>
          {integrationConfig.config.isReady ? (
            <Spin
              spinning={isTestingIntegration}
              indicator={<LoadingIndicatorWithSpin />}
            >
              {!error && (
                <Alert
                  message="Integration is active"
                  type="success"
                  showIcon
                />
              )}
              {error && (
                <Alert
                  message="Integration is not working correctly. Try Re-Authenticating"
                  type="error"
                  showIcon
                />
              )}
            </Spin>
          ) : (
            <Alert
              message="Linear integration is not ready yet. Complete the below steps to enable it"
              type="warning"
              showIcon
            />
          )}
          <div className="flex flex-row justify-between mt-8">
            <div className="text">Connected Account</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>{"Linear"}</Tag>
              </div>
              <Tooltip title="Remove Integration">
                <Button
                  type="link"
                  icon={<i className="ri-delete-bin-line"></i>}
                  onClick={() => {
                    removeIntegration();
                  }}
                  loading={isRemoving}
                ></Button>
              </Tooltip>
            </div>
          </div>
          <div className="flex flex-row justify-between my-2">
            <div className="text">Selected Team</div>
            <div className="flex flex-row items-center">
              <div className="">
                <Tag>{integrationConfig.config.teamName || "Not Selected"}</Tag>
              </div>
              {!isConfigEditMode && (
                <>
                  <Tooltip title="Configure">
                    <Button
                      type="link"
                      icon={<i className="ri-pencil-line"></i>}
                      onClick={() => {
                        setConfigEditMode(true);
                      }}
                      loading={isRemoving}
                    ></Button>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
          {isConfigEditMode && (
            <div className=" w-full">
              <Form
                form={form}
                layout="vertical"
                initialValues={integrationConfig.config}
                requiredMark={false}
                className="bold-form-labels"
                onFinish={onSaveConfig}
                onValuesChange={() => {
                  const formValues = form.getFieldsValue();
                  setFormValues(formValues);
                }}
              >
                <Form.Item
                  name="teamId"
                  label="Team"
                  rules={[
                    {
                      required: true,
                      message: "Please select a team",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select Team"
                    options={teamOptions}
                    showSearch
                    loading={isTeamsLoading}
                  />
                </Form.Item>

                <div className="flex flex-row justify-end items-center">
                  <Button
                    type="primary"
                    className="font-bold"
                    loading={isAdding}
                    onClick={onSaveConfig}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </div>
      )}

      {!integrationConfig && (
        <div className="flex flex-row justify-center items-center">
          <Spin
            spinning={isAdding}
            indicator={<LoadingIndicatorWithoutSpin />}
            wrapperClassName="flex fill-spinner"
          >
            <ExternalAuthConnector
              type="LINEAR"
              onAuthenticated={({ code }) => {
                completeIntegration(code);
              }}
              label="Connect Linear"
            />
          </Spin>
        </div>
      )}
    </>
  );
};
