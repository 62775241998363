import { Badge, Divider, Segmented } from "antd";
import { useMemo, useState } from "react";
import { PlanCard } from "./plan-card";
import { iPricingPlan } from "./pricing-plan-model";
import { BUSINESS_PRICING, TEAM_PRICING } from "./pricing-plans-data";
import "./pricing-plans.scss";

const _planGroups = [
  {
    label: "For Growing Teams",
    plans: TEAM_PRICING,
  },
  {
    label: "For Thriving Businesses",
    plans: BUSINESS_PRICING,
  },
];

export const PricingPlansTravelAgencies = ({
  onPlanSelect,
  selectedPlan,
  hideYearly,
  removeFreePlan,
  hideStartupPlans,
}: {
  onPlanSelect: (plan: iPricingPlan, isYearly?: boolean) => any;
  selectedPlan?: string;
  hideYearly?: boolean;
  removeFreePlan?: boolean;
  hideStartupPlans?: boolean;
}) => {
  const [mode, setMode] = useState<"Startup" | "Team" | "Business">("Startup");
  const [period, setPeriod] = useState("MONTHLY");

  const planGroups = useMemo(() => {
    return _planGroups.filter((e) => {
      if (hideStartupPlans && e.label.includes("Startup")) {
        return false;
      }
      if (
        e.label.includes("Deals") &&
        !window.location.href.includes("localhost")
      ) {
        return false;
      }
      return true;
    });
  }, [hideStartupPlans]);

  return (
    <div className="pricing-plans-container flex flex-col items-center max-w-2xl margin-auto">
      <div className="pricing-table-container w-full">
        {!hideYearly && (
          <div className="flex flex-row justify-center items-center">
            <div
              className="period-selector flex flex-row justify-center items-center"
              style={{ width: 320 }}
            >
              <Segmented
                value={period}
                options={[
                  { label: "Monthly", value: "MONTHLY" },
                  { label: "Yearly", value: "YEARLY" },
                ]}
                onChange={(e) => setPeriod(e.toString())}
              />
            </div>
          </div>
        )}

        {planGroups.map((planGroup) => (
          <div key={planGroup.label}>
            <Divider className="text-sm font-bold text-gray-600 dark:text-gray-300">
              {planGroup.label}
            </Divider>
            <div className="flex flex-col w-full px-4">
              {planGroup.plans
                .filter((item) =>
                  removeFreePlan ? item.price !== "Free" : true,
                )
                .map((plan, index) =>
                  plan.isRecommended ? (
                    <Badge.Ribbon text="Recommended" key={index.toString()}>
                      <PlanCard
                        key={index.toString()}
                        plan={plan}
                        selectedPlan={selectedPlan}
                        isSelectable={true}
                        onSelect={() => onPlanSelect(plan, period === "YEARLY")}
                        yearly={period === "YEARLY"}
                      />
                    </Badge.Ribbon>
                  ) : (
                    <PlanCard
                      key={index.toString()}
                      plan={plan}
                      selectedPlan={selectedPlan}
                      isSelectable={true}
                      onSelect={() => onPlanSelect(plan, period === "YEARLY")}
                      yearly={period === "YEARLY"}
                    />
                  ),
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
