import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { Form, message, Select, Switch } from "antd";
import { ExternalAuthConnector } from "components/common/external-auth-connector-button/external-auth-connector-button";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "store/modules/ui-state/ui-state.selectors";
import { selectIntegrationConfig_Slack } from "store/modules/workspace/workspace.selectors";

export const SlackIntegrationConfig = () => {
  const integrationConfig = useSelector(selectIntegrationConfig_Slack);

  const isDarkMode = useSelector(selectIsDarkMode);

  const { doAction: completeIntegration, isProcessing: isAdding } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (code: string) =>
          SDK.externalAccounts.completeIntegration({
            type: "SLACK",
            data: {
              code,
            },
          }),
        successMessage: "Channel has been connected",
        failureMessage: "Something went wrong",
        actionDependencies: [],
      }),
      [],
    );

  const [form] = Form.useForm();

  const onFinish = useCallback(
    (e) => {
      e.preventDefault();
      const process = async () => {
        try {
          await form.validateFields();
          const { apiKey } = form.getFieldsValue();
          completeIntegration(apiKey);
        } catch (e) {
          message.error("Please check your input");
        }
      };
      process();
    },
    [completeIntegration, form],
  );

  const teamOptions = useMemo(
    () =>
      (integrationConfig?.config.availableChannels || []).map((item) => ({
        value: item.webhook,
        label: item.channel,
      })),
    [integrationConfig?.config.availableChannels],
  );

  return (
    <>
      <div className="flex flex-row justify-between items-center mb-8">
        <div className="">
          Available Channels :{" "}
          {(integrationConfig?.config.availableChannels || [])
            .map((item) => item.channel)
            .join(", ")}
          {(integrationConfig?.config.availableChannels || []).length === 0 &&
            "N/A"}
        </div>
        <ExternalAuthConnector
          type="SLACK"
          onAuthenticated={({ code }) => {
            completeIntegration(code);
          }}
          label="Connect Slack Channels"
        />
      </div>
      <div className="auto-trigger flex flex-row items-center justify-between mb-4">
        <div className="font-bold">Send notifications to Slack</div>
        <Form.Item
          name={["appData", "HELP_DESK", "slackNotifications", "isActive"]}
          hasFeedback={false}
          valuePropName="checked"
          style={{ marginBottom: 0, marginLeft: 5 }}
        >
          <Switch />
        </Form.Item>
      </div>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const configEnabled = getFieldValue([
            "appData",
            "HELP_DESK",
            "slackNotifications",
            "isActive",
          ]);
          if (configEnabled) {
            return (
              <Form.Item
                label="Channel to send notification"
                name={[
                  "appData",
                  "HELP_DESK",
                  "slackNotifications",
                  "webhookUrl",
                ]}
                rules={[
                  {
                    required: true,
                    message: "Please select a channel",
                  },
                  // {
                  //   type: "url",
                  //   message: "Please enter a valid webhook URL",
                  // },
                ]}
              >
                <Select
                  placeholder="Select Channel"
                  options={teamOptions}
                  showSearch
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>
    </>
  );
};
