import { useSelector } from "react-redux";
import {
  selectOrganization,
  selectOrganizationOnboardingState,
} from "store/modules/workspace/workspace.selectors";
import { iPricingPlan } from "./pricing-plan-model";
import { PricingPlansLegacy } from "./pricing-plans-legacy";
import { PricingPlansSAAS } from "./pricing-plans-saas";
import { PricingPlansTravelAgencies } from "./pricing-plans-travel-agencies";

export const PricingPlans = (props: {
  onPlanSelect: (plan: iPricingPlan, isYearly?: boolean) => any;
  selectedPlan?: string;
  hideYearly?: boolean;
  removeFreePlan?: boolean;
  hideStartupPlans?: boolean;
}) => {
  const onboardingState = useSelector(selectOrganizationOnboardingState);
  const organization = useSelector(selectOrganization);
  const industry = onboardingState?.industry || organization?.data?.industry;

  if (industry === "Travel and Tourism") {
    return <PricingPlansTravelAgencies {...props} />;
  }

  if (industry === "LEGACY") {
    return <PricingPlansLegacy {...props} />;
  }

  return <PricingPlansSAAS {...props} />;
};
