import { useModalPanels } from "@libs/modal-panels/use-modal-panels";
import { useSDKActionWithDeps } from "@sdk/sdk.hooks";
import { iUser } from "@sdk/user-management/user-management.models";
import { Alert, Button, Divider, Form, Input, Select, Switch } from "antd";
import { ConfigurationEditorComponent } from "components/common/configuration-editor";
import { HelpCenterPromptButton } from "components/common/help-center-legacy/embeddable-components/help-center-prompt-button";
import { SectionHeader } from "components/common/section-header";
import copyToClipboard from "copy-to-clipboard";
import dayjs from "dayjs";
import _ from "lodash";

import CCEmbed from "components/common/cc-embed/cc-embed.modal-registry";
import { PerfectScrollbar } from "components/common/perfect-scroll-bar";
import { useMemo } from "react";
import { loadAllUsers } from "store/modules/users/users.helpers";
import { selectAllAPIUsers } from "store/modules/users/users.selectors";
import { useQueryWithStore } from "store/store.hooks";
import AddAPIUser from "./add-api-user/add-api-user.modal-registry";
import "./apps-and-integrations.scss";
import { AsanaIntegrationConfig } from "./asana-integration/asana-integration";
import { CalIntegrationConfig } from "./cal-integration/cal-integration";
import { CalendlyIntegrationConfig } from "./calendly-integration/calendly-integration";
import { ClickUpIntegrationConfig } from "./click-up-integration/click-up-integration";
import { FreshDeskIntegrationConfig } from "./fresh-desk-integration/fresh-desk-integration";
import { GitHubIntegrationConfig } from "./github-integration/github-integration";
import { GoogleMeetIntegrationConfig } from "./google-meet-integration/google-meet-integration";
import { HubSpotIntegrationConfig } from "./hubspot-integration/hubspot-integration";
import { JiraIntegrationConfig } from "./jira-integration/jira-integration";
import { LinearIntegrationConfig } from "./linear-integration/linear-integration";
import { MailchimpListSelector } from "./mailchimp-list-selector/mailchimp-list-selector";
import { ManageConnectedApps } from "./manage-conncted-apps/manage-conncted-apps";
import { NotionIntegrationConfig } from "./notion-integration/notion-integration";
import { PipeDriveIntegrationConfig } from "./pipedrive-integration/pipedrive-integration";
import { ShopifyIntegrationStatus } from "./shopify-integration/shopify-integration";
import { SlackIntegrationConfig } from "./slack-integration/slack-integration";
import { StripeIntegrationConfig } from "./stripe-integration/stripe-integration";
import { ZenDeskIntegrationConfig } from "./zendesk-integration/zendesk-integration";
import { ZohoDeskIntegrationConfig } from "./zohodesk-integration/zohodesk-integration";

export const AppsAndIntegrations = () => {
  const { state: apiUsers, retry: reload } = useQueryWithStore(
    selectAllAPIUsers,
    loadAllUsers(),
  );

  const zapierTokens = useMemo(() => {
    return _.filter(apiUsers, (user) => user.data.lastName.includes("Zapier"));
  }, [apiUsers]);

  const apiTokens = useMemo(() => {
    return _.filter(apiUsers, (user) => !user.data.lastName.includes("Zapier"));
  }, [apiUsers]);

  const { changePanelState, triggerTempModal } = useModalPanels();

  const isLocalHost = window.location.href.includes("localhost");

  return (
    <PerfectScrollbar
      className="w-full h-full animated fadeInLeftMin p-8"
      options={{ suppressScrollX: true }}
    >
      <SectionHeader
        title="Apps and Integrations"
        icon={<i className="ri-plug-line"></i>}
        description="Make ClickConnector work with other tools you use in your day-to-day to supercharge your workflow"
      />
      <Divider />

      <div className="apps w-full">
        <div className="flex flex-row justify-between items-center">
          <div className="font-lg font-bold my-8">Native Apps</div>
          <Button
            type="text"
            icon={<i className="ri-arrow-right-circle-line"></i>}
            className=""
            onClick={() => {
              triggerTempModal(CCEmbed, { type: "FEATURE_REQUEST" });
            }}
          >
            Request an Integration
          </Button>
        </div>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/slack-icon.svg"
              style={{ width: 40 }}
              alt=""
            />
          }
          title={"Slack Notification"}
          description={"Receive notifications via Slack"}
          entityType={"WORKSPACE"}
        >
          <SlackIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/mailchimp.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Mailchimp"}
          description={"Sync Contacts with your mailing List"}
          entityType={"WORKSPACE"}
        >
          <div className="auto-trigger flex flex-row items-center justify-between mb-4">
            <div className="font-bold">Activate Syncing</div>
            <Form.Item
              name={["appData", "CRM", "mailChimpIntegration", "isActive"]}
              hasFeedback={false}
              valuePropName="checked"
              style={{ marginBottom: 0, marginLeft: 5 }}
            >
              <Switch />
            </Form.Item>
          </div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const configEnabled = getFieldValue([
                "appData",
                "CRM",
                "mailChimpIntegration",
                "isActive",
              ]);
              if (configEnabled) {
                return (
                  <>
                    <Form.Item
                      label="API Key"
                      name={[
                        "appData",
                        "CRM",
                        "mailChimpIntegration",
                        "apiToken",
                      ]}
                      className="m-0"
                      help={
                        <a
                          href="https://mailchimp.com/help/about-api-keys/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          How to find the API Key
                        </a>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please enter your mailchimp API Key",
                        },
                      ]}
                    >
                      <Input type="password" />
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const apiToken = getFieldValue([
                          "appData",
                          "CRM",
                          "mailChimpIntegration",
                          "apiToken",
                        ]);
                        return (
                          <Form.Item
                            label="Mailchimp List"
                            name={[
                              "appData",
                              "CRM",
                              "mailChimpIntegration",
                              "mailChimpListId",
                            ]}
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please select your mailchimp audience (List)",
                              },
                            ]}
                          >
                            <MailchimpListSelector apiKey={apiToken} />
                          </Form.Item>
                        );
                      }}
                    </Form.Item>
                    <Form.Item
                      label="Which Contacts to Sync?"
                      name={[
                        "appData",
                        "CRM",
                        "mailChimpIntegration",
                        "syncTarget",
                      ]}
                      // className="m-0"
                      rules={[
                        {
                          required: true,
                          message: "Please select an option",
                        },
                      ]}
                    >
                      <Select>
                        <Select.Option value={"ALL_CONTACTS"}>
                          All Contacts
                        </Select.Option>
                        <Select.Option value={"GIVEN_CONSENT"}>
                          Contacts that has opted in for to receive marketing
                          materials
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </>
                );
              }
            }}
          </Form.Item>
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/shopify.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Shopify"}
          description={
            "Integrate your Shopify Store with ClickConnector for Seamless Customer Support"
          }
          entityType={"WORKSPACE"}
        >
          <ShopifyIntegrationStatus />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/stripe-logo.svg"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Stripe"}
          description={
            "Integrate your Stripe Account to see the invoices and payments associated with Stripe Records"
          }
          entityType={"WORKSPACE"}
        >
          <StripeIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/linear.svg"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Linear"}
          description={
            "Integrate your Linear Workspace and link trackers with your existing or new Linear Issues"
          }
          entityType={"WORKSPACE"}
        >
          <LinearIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/notion-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Notion"}
          description={
            "Integrate your Notion Account to link conversations to your Notion database records"
          }
          entityType={"WORKSPACE"}
        >
          <NotionIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/asana-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Asana"}
          description={
            "Integrate your Asana Account to link conversations to your Asana tasks"
          }
          entityType={"WORKSPACE"}
        >
          <AsanaIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/click-up-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"ClickUp"}
          description={
            "Integrate your ClickUp Account to link conversations to your ClickUp tasks"
          }
          entityType={"WORKSPACE"}
        >
          <ClickUpIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/jira-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Jira"}
          description={
            "Integrate your Jira Account to link conversations to your Jira tasks"
          }
          entityType={"WORKSPACE"}
        >
          <JiraIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/github-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Git Hub"}
          description={
            "Integrate your Git Hub Repository and link conversations to your GitHub Issues"
          }
          entityType={"WORKSPACE"}
        >
          <GitHubIntegrationConfig />
        </ConfigurationEditorComponent>

        {/* // CRM */}

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/hubspot-logo.jpg"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Hub Spot"}
          description={
            "Integrate your Hubspot CRM and see customer data right in your ClickConnector Inbox"
          }
          entityType={"WORKSPACE"}
        >
          <HubSpotIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/pipe-drive-logo-2.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Pipe Drive"}
          description={
            "Integrate your Pipe Drive CRM and see customer data right in your ClickConnector Inbox"
          }
          entityType={"WORKSPACE"}
        >
          <PipeDriveIntegrationConfig />
        </ConfigurationEditorComponent>

        {/* Help Desk */}

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/zen-desk-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Zen Desk"}
          description={
            "Integrate your Zendesk Account to link conversations to your Zendesk tickets"
          }
          entityType={"WORKSPACE"}
        >
          <ZenDeskIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/fresh-desk-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Fresh Desk"}
          description={
            "Integrate your Fresh Desk Account to link conversations to your Fresh Desk tickets"
          }
          entityType={"WORKSPACE"}
        >
          <FreshDeskIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/zoho-desk-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Zoho Desk"}
          description={
            "Integrate your Zoho Desk Account to link conversations to your Zoho Desk tickets"
          }
          entityType={"WORKSPACE"}
        >
          <ZohoDeskIntegrationConfig />
        </ConfigurationEditorComponent>

        {/* Meetings and Calls */}

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/aircall-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Air Call"}
          description={
            "Integrate your Air Call Account to make Aircalls directly from ClickConnector"
          }
          entityType={"WORKSPACE"}
        >
          <Alert
            message="This integration is currently in Private Beta. To enable it in your workspace, please reach out to us via the live chat support."
            type="info"
            showIcon
          />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/google-meet-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Google Meet"}
          description={
            "Integrate your Google Meet Account to create Google Meet links directly from ClickConnector"
          }
          entityType={"WORKSPACE"}
        >
          <GoogleMeetIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/calendly-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Calendly"}
          description={
            "Integrate your Calendly Account to create send calendar links directly from ClickConnector"
          }
          entityType={"WORKSPACE"}
        >
          <CalendlyIntegrationConfig />
        </ConfigurationEditorComponent>

        <ConfigurationEditorComponent
          icon={
            <img
              src="/assets/integrations/cal-logo.png"
              style={{ width: 45 }}
              alt=""
              className=""
            />
          }
          title={"Cal"}
          description={
            "Integrate your Cal Account to create send calendar links directly from ClickConnector"
          }
          entityType={"WORKSPACE"}
        >
          <CalIntegrationConfig />
        </ConfigurationEditorComponent>

        <div className="flex flex-row justify-between items-center">
          <div className="font-lg font-bold my-8">Zapier Apps</div>
          {zapierTokens.length > 0 && (
            <Button
              type="text"
              icon={<i className="ri-add-line"></i>}
              onClick={() =>
                changePanelState(AddAPIUser, true, { type: "Zapier App" })
              }
            >
              Create New Zapier Token
            </Button>
          )}
        </div>

        {zapierTokens.map((user) => (
          <APIUserCard user={user} key={user.id} />
        ))}

        <HelpCenterPromptButton
          type="BANNER"
          text={
            <div className="text-lg p-3">Learn how to Connect Zapier 📺</div>
          }
          description=""
          icon="LEARN"
          articleId="fc89e0-04406"
          className="my-4"
        />

        {zapierTokens.length === 0 && (
          <div
            className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700  flex-col flex p-6 pb-2"
            style={{ height: 150 }}
          >
            <div className="flex flex-row items-center justify-center">
              <div className="icon">
                <img
                  src="/assets/integrations/zapier.svg"
                  style={{ width: 120 }}
                  alt=""
                />
              </div>
              {/* <div className="name text-xl font-bold ml-2">Stripe</div> */}
            </div>
            <div className="description text-gray-600 flex-1 text-center">
              Connect to thousands of apps via zapier
            </div>
            <div className="footer">
              <Button
                type="text"
                icon={<i className="ri-arrow-right-circle-line"></i>}
                className="font-bold"
                block
                onClick={() =>
                  changePanelState(AddAPIUser, true, { type: "Zapier App" })
                }
              >
                Configure New Zapier Integration
              </Button>
            </div>
          </div>
        )}

        <div className="flex flex-row justify-between items-center">
          <div className="font-lg font-bold my-8">API Integrations</div>
          {apiTokens.length > 0 && (
            <Button
              type="text"
              onClick={() =>
                changePanelState(AddAPIUser, true, { type: "API App" })
              }
              icon={<i className="ri-add-line"></i>}
            >
              Create New API Token
            </Button>
          )}
        </div>

        {apiTokens.map((user) => (
          <APIUserCard user={user} key={user.id} />
        ))}

        {apiTokens.length === 0 && (
          <div
            className="bg-white dark:bg-gray-900 rounded-lg border border-dashed border-gray-200 dark:border-gray-700  flex-col flex p-6 hover:bg-gray-100 cursor-pointer"
            style={{}}
            onClick={() =>
              changePanelState(AddAPIUser, true, { type: "API App" })
            }
          >
            <div className="flex flex-row items-center justify-center">
              <div className="icon">
                <i className="ri-add-line text-2xl"></i>
              </div>
              <div className="name text-xl font-bold ml-2">
                Create New API Token
              </div>
            </div>
            <div className="description text-gray-600 flex-1 text-center">
              Bring in data from other platforms for continuity
            </div>
          </div>
        )}

        <div className="font-lg  my-8 text-center">
          The API documentation is accessible in various formats; feel free to
          utilize one to facilitate code generation as well.
        </div>
        <div className="m-4 flex flex-row justify-center items-center gap-4 w-full mb-16">
          <img
            src="/assets/integrations/post-man-logo.png"
            width={200}
            className="bg-white p-2 rounded-lg cursor-pointer"
            onClick={() => {
              const url =
                "https://www.postman.com/click-connector/click-connector/overview";
              const newWindow = window.open(url);
            }}
          />
          <img
            src="/assets/integrations/openapi-logo.svg"
            width={200}
            className="bg-white p-2 rounded-lg cursor-pointer"
            onClick={() => {
              const url =
                "https://engine.clickconnector.app/open-api-spec/api.yaml";
              const newWindow = window.open(url);
            }}
          />
          <img
            src="/assets/integrations/swagger-logo.png"
            width={200}
            className="bg-white p-2 rounded-lg cursor-pointer"
            onClick={() => {
              const url = "https://engine.clickconnector.app/docs/";
              const newWindow = window.open(url);
            }}
          />
        </div>

        {/* <div
          className="bg-white dark:bg-gray-900 rounded-lg border border-dashed border-gray-200 dark:border-gray-700  flex-col flex p-6 hover:bg-gray-100 cursor-pointer mt-8"
          style={{}}
         
        >
          <div className="flex flex-row items-center justify-center">
            <div className="icon">
              <i className="ri-file-text-line text-2xl"></i>
            </div>
            <div className="name text-xl font-bold ml-2">
              View API Documentation
            </div>
          </div>
        </div> */}

        <ManageConnectedApps />
      </div>
    </PerfectScrollbar>
  );
};

export const APIUserCard = ({ user }: { user: iUser }) => {
  const { doAction: copyAccessToken, isProcessing: isActionProcessing } =
    useSDKActionWithDeps(
      () => ({
        action: (SDK) => (apiUserId: string) =>
          SDK.getAPIUserSecret(apiUserId).then((d) => {
            return copyToClipboard(d.accessToken);
          }),
        successMessage: "Token has been copied to your clipboard",
        failureMessage: "Something went wrong",
      }),
      [],
    );

  const type = useMemo(
    () =>
      user.data.lastName.includes("Zapier") ? "Zapier Token" : "API Token",
    [user.data.lastName],
  );

  return (
    <div className="bg-white dark:bg-gray-900 rounded-lg border border-gray-200 dark:border-gray-700  flex-row flex items-center p-4 mb-2">
      <div className="flex flex-row items-center">
        <div className="icon">
          {type === "Zapier Token" && (
            <img
              src="/assets/integrations/zapier-logo.png"
              style={{ width: 30 }}
              alt=""
            />
          )}

          {type === "API Token" && (
            <i className="ri-terminal-box-line text-2xl"></i>
          )}
        </div>
      </div>
      <div className="flex flex-col  ml-2 flex-1">
        <div className="name text-lg font-bold">{user.data.firstName}</div>
        <div className="description text-gray-600 flex-1">
          Created on {dayjs(user.metaData.joined).format("DD-MM-YYYY")}
        </div>
      </div>
      <Button
        type="text"
        onClick={() => {
          copyAccessToken(user.id);
        }}
        icon={<i className="ri-clipboard-fill"></i>}
      >
        Copy Secret Token
      </Button>
    </div>
  );
};
